import { useState } from "react";
import gql from "graphql-tag";

import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Spinner } from "components/Spinner/Spinner";
import { Table } from "components/Table/Table";
import { AllServerKeys, DeleteServerKey } from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { AppFlavor } from "../../platform";
import { APIVersionSelector } from "./APIVersionSelector";
import { ServerKeyComposer } from "./ServerKeyComposer";

gql`
  # schema = ORGANIZATION_USER
  fragment ServerKey on ServerKey {
    uuid
    publicId
    name
    description
    createdAt
  }

  query AllServerKeys {
    serverKeys {
      ...ServerKey
    }
  }

  mutation DeleteServerKey($uuid: UUID!) {
    deleteServerKey(uuid: $uuid)
  }
`;

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

type ComposerState = { mode: "edit"; serverKeyUuid: UUID };

export const ServerKeys = ({ appFlavor }: { appFlavor: AppFlavor }) => {
  const t = useTranslation();
  const { data, loading } = useQuery(AllServerKeys);
  const [deleteServerKey] = useMutation(DeleteServerKey);
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>();
  const [composerState, setComposerState] = useState<ComposerState>();
  const [showingDetails, setShowingDetails] = useState(false);

  return (
    <>
      <ServerKeyComposer
        onClose={() => {
          setComposerState(undefined);
        }}
        serverKey={
          composerState?.mode === "edit"
            ? data?.find(
                (serverKey) => serverKey.uuid === composerState.serverKeyUuid,
              )
            : undefined
        }
        showComposer={!!composerState}
      />
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("server_keys.delete_server_api_key")}
          suffix={t("server_keys.to_delete_this_server_api_key")}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <div>
        <APIVersionSelector appFlavor={appFlavor} />
        {loading ? (
          <Spinner />
        ) : data ? (
          <>
            <Table
              elements={data}
              onShowDetails={(show) => setShowingDetails(show)}
              fieldHeaders={[
                t("developers.server_keys.server_keys.public_id"),
                t("developers.server_keys.server_keys.name"),
                t("developers.server_keys.server_keys.description"),
                t("developers.server_keys.server_keys.created_at"),
              ]}
              fields={(serverKey) => [
                serverKey.publicId,
                <span key={`name-${serverKey.uuid}`} className="truncate">
                  {serverKey.name}
                </span>,
                serverKey.description,
                <span
                  key={`date-${serverKey.uuid}`}
                  className="whitespace-nowrap"
                >
                  {serverKey.createdAt.format("date")}
                </span>,
              ]}
              menuItems={(serverKey) => [
                {
                  icon: "edit",
                  text: t("developers.server_keys.server_keys.edit"),
                  onClick: (close: () => void) => {
                    close();
                    setComposerState({
                      mode: "edit",
                      serverKeyUuid: serverKey.uuid,
                    });
                  },
                },
                {
                  icon: "trash",
                  text: t(
                    "developers.server_keys.server_keys.permanently_delete",
                  ),
                  className: "text-danger",
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      onConfirm: () =>
                        deleteServerKey(
                          { uuid: serverKey.uuid },
                          {
                            onSuccess: (_, client) => {
                              notifier.success(
                                t("server_keys.server_api_key_deleted"),
                              );
                              setConfirmationModalState(undefined);
                              client.remove("ServerKey", serverKey.uuid);
                            },
                          },
                        ),
                    });
                  },
                },
              ]}
            />
            {!showingDetails && (
              <div className="float-right my-16">
                {data.length}{" "}
                {t("developers.server_keys.server_keys.server_api_keys")}
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
