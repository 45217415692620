import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Table } from "components/Table/Table";
import { useUser } from "contexts/User/UserContext";
import {
  DeleteCopilotApiDeveloper,
  MeWithCopilotApiDevelopers,
} from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";

import { CreateApiAdmin } from "./CreateApiAdmin";

gql`
  # schema = COPILOT_API_DEVELOPER
  query MeWithCopilotApiDevelopers {
    me {
      developer {
        uuid
        subOrganization {
          uuid
          copilotApiDevelopers {
            totalCount
            resultPage(page: { numberOfItems: -1 }) {
              data {
                uuid
                email
                createdAt
              }
            }
          }
        }
      }
    }
  }

  mutation DeleteCopilotApiDeveloper($uuid: UUID!) {
    deleteCopilotApiDeveloper(uuid: $uuid)
  }
`;

export const ApiAdmins = () => {
  const t = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState<
    { uuid: UUID; email: string } | undefined
  >(undefined);
  const { data, refetch } = useQuery(MeWithCopilotApiDevelopers);
  const copilotApiDevelopers =
    data?.developer.subOrganization.copilotApiDevelopers.resultPage.data ?? [];
  const { email, organizationOwnerEmail } = useUser();
  const [deleteCopilotApiDeveloper] = useMutation(DeleteCopilotApiDeveloper, {
    onSuccess: () => {
      void refetch();
      setDeleteModalState(undefined);
    },
  });
  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <div className="flex items-center">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("developers.api_admins.table_title")}
          </h1>

          <Button
            label={t("developers.api_admins.add_admin_button_label")}
            onClick={() => setIsCreateModalOpen(true)}
            className="ml-auto"
          />
        </div>
      </div>
      {isCreateModalOpen && (
        <CreateApiAdmin
          onClose={() => {
            setIsCreateModalOpen(false);
            void refetch();
          }}
        />
      )}
      {deleteModalState && (
        <ControlledDeletionConfirmationModal
          title={t("developers.api_admins.remove_modal_title")}
          suffix={t("developers.api_admins.remove_modal_suffix", {
            email: deleteModalState.email,
          })}
          onHide={() => setDeleteModalState(undefined)}
          ctaLabel={t("developers.api_admins.remove_modal_cta_label")}
          onConfirm={async () => {
            await deleteCopilotApiDeveloper({
              uuid: deleteModalState.uuid,
            });
          }}
        />
      )}
      <Table
        elements={copilotApiDevelopers}
        fieldHeaders={[
          t("developers.api_admins.email_table_field"),
          t("developers.api_admins.created_at_table_field"),
        ]}
        fields={(copilotApiDeveloper) => [
          copilotApiDeveloper.email,
          copilotApiDeveloper.createdAt.format("date"),
        ]}
        menuItems={(copilotApiDeveloper) => [
          {
            icon: "trash",
            text: t("developers.api_admins.remove_table_field"),
            onClick: () => {
              setDeleteModalState({
                uuid: copilotApiDeveloper.uuid,
                email: copilotApiDeveloper.email,
              });
            },
            disable: {
              if: [email, organizationOwnerEmail].includes(
                copilotApiDeveloper.email,
              ),
              tooltip:
                copilotApiDeveloper.email === email
                  ? t("developers.api_admins.remove_yourself_tooltip")
                  : t("developers.api_admins.remove_owner_tooltip"),
            },
          },
        ]}
      />
    </Background>
  );
};
