import { Background } from "components/Background/Backgound";
import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";

import { ServerKeys } from "../../../shared/Developers/ServerKeys/ServerKeys";

export const ApiKeys = () => {
  const t = useTranslation();

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <div className="flex items-center">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("developers.server_keys.api_keys.api_keys")}
          </h1>
        </div>
      </div>
      <div className="flex">
        <Icon name="warning" className="text-danger-300 mr-10" />
        <div className="text-danger-300">
          <Translation
            k="api_keys.warning_deprecated"
            components={{ b: ({ children }) => <b children={children} /> }}
          />
        </div>
      </div>
      <ServerKeys appFlavor="COPILOT_API" />
    </Background>
  );
};
