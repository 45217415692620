import { createContext, useContext } from "react";

import { Maybe } from "base-types";
import { AccessProps } from "types";

export const UserContext = createContext<
  | (AccessProps & {
      uuid: Maybe<UUID>;
      email: Maybe<string>;
      timezone: TimeZone;
      subOrganizationTimezone: TimeZone;
      organizationStringId?: string;
      subOrganizationUuid?: string;
      organizationOwnerEmail: Maybe<string>;
    })
  | null
>(null);
UserContext.displayName = "UserContext";

export const useCopilotApiUser = () => {
  const context = useContext(UserContext);
  if (context?.type !== "COPILOT_API_DEVELOPER") {
    throw new Error("CopilotApiUser UserContext not available");
  }
  return context;
};

export const useMaybeUser = () => useContext(UserContext);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) throw new Error("UserContext not available");
  return context;
};
